import React from 'react'
import mainImages from './ClansImages/Main'
import { useState, useEffect } from 'react'
import './LandingPage.css'
import { Carousel } from 'react-responsive-carousel'
import ReactPlayer from 'react-player'
import './LandingPage.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import banner from '../images/AlienWorldBanner.jpg'
import 'animate.css/animate.min.css'
import THEBITCOINKID from '../images/THEBITCOINKID.gif'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import 'animate.css/animate.min.css'

export const Home = () => {
  const [age, setAge] = React.useState(1)

  const handleChange = event => {
    setAge(event.target.value)
  }

  const [deviceCheck, setDeviceCheck] = useState('700px')
  const [playerWidth, setPlayerWidth] = useState('190%')
  const [playerHeight, setPlayerHeight] = useState('350px')

  useEffect(() => {
    let screenSize = window.screen.width
    console.log(screenSize)
    if (screenSize <= 375) {
      setDeviceCheck('350px')
      setPlayerWidth('120%')
      setPlayerHeight('230px')
    } else if (screenSize <= 460) {
      setDeviceCheck('400px')
      setPlayerWidth('135%')
      setPlayerHeight('250px')
    } else if (screenSize <= 560) {
      setDeviceCheck('420px')
      setPlayerWidth('155%')
      setPlayerHeight('250px')
    } else if (screenSize <= 768) {
      setDeviceCheck('600px')
    }

    var elmntToView = document.getElementById('corousel-id')
    elmntToView.scrollIntoView()
  }, [])

  const [cAutoPlay, setCAutoPlay] = useState(true)
  const [nft, setNft] = useState(0)

  function onClickItem () {
    setCAutoPlay(false)
    const modal = document.querySelector('#modal1')
    const closeBtn = document.querySelector('.close')
    modal.style.display = 'flex'
    closeBtn.addEventListener('click', () => {
      modal.style.display = 'none'
      setCAutoPlay(true)
    })
    modal.addEventListener('click', () => {
      modal.style.display = 'none'
      setCAutoPlay(true)
    })
  }

  const onChange = e => {
    // setNft(e)
  }

  const [playing, setPlaying] = useState(false)

  //POPUP

  function handleClick () {
    const modal = document.querySelector('#modal')
    const closeBtn = document.querySelector('.close')
    modal.style.display = 'flex'
    setPlaying(true)
    closeBtn.addEventListener('click', () => {
      modal.style.display = 'none'
      setPlaying(false)
    })
    modal.addEventListener('click', () => {
      modal.style.display = 'none'
      setPlaying(false)
    })
  }

  const corouselSelect = () => {
    switch (
      1 // switch (age)
    ) {
      case 1:
        return mainImages.map((objects, key) => (
          <div className='imageCorousel'>
            <img src={objects} alt='Here' />
          </div>
        ))
        break
      case 2:
        return mainImages.map((objects, key) => (
          <div className='imageCorousel'>
            <img src={objects} alt='Here' />
          </div>
        ))
        break
      default:
        break
    }
  }
  const [chiko, setChiko] = useState(1)

  const setAlienFunc = () => {
    setChiko(1)
    document.querySelector('.alien').classList.add('selected')
    document.querySelector('.chiko').classList.remove('selected')
    document.querySelector('.kid').classList.remove('selected')
  }

  const setChikoFunc = () => {
    setChiko(2)
    document.querySelector('.chiko').classList.add('selected')
    document.querySelector('.alien').classList.remove('selected')
    document.querySelector('.kid').classList.remove('selected')
  }

  const setDaBitcoinKidFunc = () => {
    setChiko(3)
    document.querySelector('.kid').classList.add('selected')
    document.querySelector('.alien').classList.remove('selected')
    document.querySelector('.chiko').classList.remove('selected')
  }

  const linkSelect = () => {
    switch (chiko) {
      case 1:
        return (
          <p className='links-div'>
            For more Info Visit{' '}
            <a href='https://play.alienworlds.io/'>Alien Worlds</a>
          </p>
        )
        break
      case 2:
        return (
          <p className='links-div'>
            For more Info Visit <a href=' https://chikoroko.art/'>Chiko&Roko</a>
          </p>
        )
        break
      case 3:
        return (
          <p className='links-div'>
            For more Info Visit{' '}
            <a href='https://cryptomoonboys.com/'>Da Bitcoin Kid</a>
          </p>
        )
        break
    }
  }

  const menuSelect = () => {
    switch (chiko) {
      case 1:
        return (
          <>
            <div>
              <AnimationOnScroll
                animateIn='animate__fadeInRight'
                animateOut='animate__fadeOutRight'
                duration={1}
              >
                <div className='corousel-div'>
                  <Carousel
                    onChange={onChange}
                    centerMode={true}
                    infiniteLoop={true}
                    autoPlay={cAutoPlay}
                    width={deviceCheck}
                    useKeyboardArrows={true}
                    centerSlidePercentage={90}
                    transitionTime={1000}
                    renderIndicator={false}
                  >
                    {corouselSelect()}
                  </Carousel>
                </div>
              </AnimationOnScroll>
              <div className='banner-div'>
                <img src={banner} alt='banner' width='20%'/>
              </div>
            </div>
          </>
        )
        break
      case 2:
        return (
          <div className='reactplayerDiv'>
            <ReactPlayer
              url='https://youtu.be/fc37f1Epbo4'
              controls={true}
              onPlay={() => setPlaying(true)}
              playing={playing}
              width={playerWidth}
              height={playerHeight}
              loop={false}
              className='video-player'
            />
          </div>
        )
        break
      case 3:
        return (
          <img
            src={THEBITCOINKID}
            className='bitcoin-kid-gif'
            style={{ borderRadius: '10px' }}
            alt=''
          />
        )
        break

      default:
        break
    }
  }
  return (
    <>
      <div id='corousel-id'></div>
      <div className='main-div-homepage'>
        <div>
          <div
            className='button-valued alien'
            id='button-2'
            onClick={setAlienFunc}
          >
            <div id='slide'></div>
            <a href='#' className='a-href'>
              Alien Worlds
            </a>
          </div>
          <div
            className='button-valued chiko'
            id='button-2'
            onClick={setChikoFunc}
          >
            <div id='slide'></div>
            <a href='#' className='a-href'>
              Chiko & Roko
            </a>
          </div>
          <div
            className='button-valued kid'
            id='button-2'
            onClick={setDaBitcoinKidFunc}
          >
            <div id='slide'></div>
            <a href='#' className='a-href'>
              DaBitcoinKid
            </a>
          </div>
        </div>
        <div>{menuSelect()}</div>
        <div>{linkSelect()}</div>
      </div>
    </>
  )
}
