import React, { useState } from 'react'
import './input.css'

function InputComp (props) {
  const [labelClass, setLabelClass] = useState('simpleLabel')

  let changeLableOnFocus = () => {
    setLabelClass('animateAbove')
  }

  let changeLableOnBlur = e => {
    if (e.target.value.length !== 0) {
      setLabelClass('animateAbove')
    } else {
      setLabelClass('animateDown')
    }
  }

  return (
    <center>
      <div id='floatContainer' className='float-container'>
        <label id='labelId' className={labelClass} htmlFor={props.id}>
          {props.label}
        </label>
        <input
          type={props.type}
          onBlur={changeLableOnBlur}
          onFocus={changeLableOnFocus}
          className='inputField'
          id={props.id}
          required
        />
      </div>
    </center>
  )
}

export default InputComp
