import React from 'react'
import { useState, useEffect, Suspense } from 'react'
import './LandingPage.css'
import LogoIcon from '../images/Logos/Logo1.png'
import telegram from '../images/svgs/telegram.svg'
import ReactPlayer from 'react-player'
import { Carousel } from 'react-responsive-carousel'
import data from '../JSON/Character.json'
import Bounce from 'react-reveal/Bounce'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

import {
  FacebookFilled,
  InstagramFilled,
  TwitterSquareFilled
} from '@ant-design/icons'

import { AnimationOnScroll } from 'react-animation-on-scroll'
import 'animate.css/animate.min.css'
import { Link } from 'react-router-dom'

const About = React.lazy(() => import('../components/About'))
const News = React.lazy(() => import('../components/News'))

const LandingPage = () => {
  const [deviceCheck, setDeviceCheck] = useState('42%')

  useEffect(() => {
    let screenSize = window.screen.width
    console.log(screenSize)
    if (screenSize < 768) {
      setDeviceCheck('100%')
    }
  }, [])

  const [cAutoPlay, setCAutoPlay] = useState(false)
  const [nft, setNft] = useState(0)

  function onClickItem () {
    setCAutoPlay(false)
    const modal = document.querySelector('#modal1')
    const closeBtn = document.querySelector('.close')
    modal.style.display = 'flex'
    closeBtn.addEventListener('click', () => {
      modal.style.display = 'none'
      setCAutoPlay(true)
    })
    modal.addEventListener('click', () => {
      modal.style.display = 'none'
      setCAutoPlay(true)
    })
  }

  function importAll (r) {
    let images = {}
    r.keys().map((item, index) => {
      images[item.replace('./', '')] = r(item)
    })
    return images
  }

  const images = importAll(
    require.context('../images/nfts', false, /\.(png|jpe?g|svg)$/)
  )

  const onChange = e => {
    setNft(e)
  }

  // Corousel
  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      let socialMedia = document.getElementById('socialMedia')
      let socialMediaFacebookFilled = document.getElementById(
        'socialMedia-FacebookFilled'
      )
      let socialMediaInstagramFilled = document.getElementById(
        'socialMedia-InstagramFilled'
      )
      let socialMediaTwitterSquareFilled = document.getElementById(
        'socialMedia-TwitterSquareFilled'
      )
      let socialMediatelegram = document.getElementById('socialMedia-telegram')

      if (scrollY !== 0) {
        socialMediaFacebookFilled.style.fontSize = '30px'
        socialMediaInstagramFilled.style.fontSize = '30px'
        socialMediaTwitterSquareFilled.style.fontSize = '30px'
        socialMediatelegram.style.width = '30px'
        socialMedia.style.height = '45px'
        socialMedia.style.backgroundColor = '#000000d0'
      } else {
        socialMediaFacebookFilled.style.fontSize = '40px'
        socialMediaInstagramFilled.style.fontSize = '40px'
        socialMediaTwitterSquareFilled.style.fontSize = '40px'
        socialMediatelegram.style.width = '40px'
        socialMedia.style.height = '55px'
        socialMedia.style.backgroundColor = 'black'
      }
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const [playing, setPlaying] = useState(false)

  //POPUP

  function handleClick () {
    const modal = document.querySelector('#modal')
    const closeBtn = document.querySelector('.close')
    modal.style.display = 'flex'
    setPlaying(true)
    closeBtn.addEventListener('click', () => {
      modal.style.display = 'none'
      setPlaying(false)
    })
    modal.addEventListener('click', () => {
      modal.style.display = 'none'
      setPlaying(false)
    })
  }

  // POPUP

  function onInstructionButton () {
    const modal = document.querySelector('#modal2')
    const closeBtn = document.querySelector('.close')
    modal.style.display = 'flex'
    closeBtn.addEventListener('click', () => {
      modal.style.display = 'none'
    })
    modal.addEventListener('click', () => {
      modal.style.display = 'none'
    })
  }

  const handleSubmit = async event => {
    event.preventDefault()
  }

  return (
    <>
      <div className='modal' id='modal1'>
        <div className='modal_content mRed'>
          <span className='close'>&times;</span>
          <div className='Desc-NFT'>
            <h1>Description</h1>
            <hr />
            <span>
              <p className='title'> Name: </p>&nbsp;
              <p className='title-data'>{data[nft].name}</p>
            </span>
            <span>
              <p className='title'>hitpoints: </p>&nbsp;
              <p className='title-data'>{data[nft].hitpoints}</p>
            </span>
            <span>
              <p className='title'>strength: </p>&nbsp;
              <p className='title-data'>{data[nft].strength}</p>
            </span>
            <span>
              <p className='title'>intelligence: </p>&nbsp;
              <p className='title-data'>{data[nft].intelligence}</p>
            </span>
            <span>
              <p className='title'>defense: </p>&nbsp;
              <p className='title-data'>{data[nft].defense}</p>
            </span>
            <span>
              <p className='title'>a1: </p>&nbsp;
              <p className='title-data'>{data[nft].a1}</p>
            </span>
            <span>
              <p className='title'>a2: </p>&nbsp;
              <p className='title-data'>{data[nft].a2}</p>
            </span>
            <span>
              <p className='title'>a3: </p>&nbsp;
              <p className='title-data'>{data[nft].a3}</p>
            </span>
            <span>
              <p className='title'>d1: </p>&nbsp;
              <p className='title-data'>{data[nft].d1}</p>
            </span>
            <span>
              <p className='title'>d2: </p>&nbsp;
              <p className='title-data'>{data[nft].d2}</p>
            </span>
            <span>
              <p className='title'>d3: </p>&nbsp;
              <p className='title-data'>{data[nft].d3}</p>
            </span>
          </div>
        </div>
      </div>
      <div className='modal' id='modal'>
        <div className='modal_content-video'>
          <span className='close'>&times;</span>
          <ReactPlayer
            url='https://www.youtube.com/watch?v=MKwxYj6wkvs'
            controls={true}
            onPlay={() => setPlaying(true)}
            playing={playing}
            width='90%'
            height={720}
            loop={false}
            // fallback={true}
            className='video-player'
          />
        </div>
      </div>

      <div className='modal' id='modal2'>
        <div className='modal_content'>
          <span className='close'>&times;</span>
          <div className='Instruction-div'>
            <h1>Instructions:</h1>
            <hr />
            <p>
              <em>1.</em> Connect your wax wallet - Log into the game using your
              wax wallet to start playing. The HODL warriors supports both Wax
              cloud wallet and Anchor.
            </p>
            <p>
              <em>2.</em> Select your card - Choose which NFT you would like to
              battle with. The game currently supports the Grafitti Kings
              "Bitcoin kids" collection, however more will be coming soon"
            </p>
            <p>
              <em>3.</em> Choose your stake - For each game you will pay some
              LFGK tokens, the winning player will take away the whole pot as a
              prize!
            </p>
            <p>
              <em>4.</em> Battle - Select from between 4-6 abilities in a turn
              based battle, outsmart your opponent and use tactics to win.
            </p>
          </div>
        </div>
      </div>

      <Bounce bottom cascade>
        <img className='image-hd-logo2' src={LogoIcon} alt='here' />
      </Bounce>
      <div style={{ marginBottom: '4%' }}>
        <Bounce left cascade>
          <div className='span-wrapper'>
            <div className='link_wrapper'>
              <Link
                className='a-tag'
                // onClick={handleClick}
                to={'/signin'}
              >
                Play
              </Link>
              <div className='icon'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 268.832 268.832'
                >
                  <path d='M265.17 125.577l-80-80c-4.88-4.88-12.796-4.88-17.677 0-4.882 4.882-4.882 12.796 0 17.678l58.66 58.66H12.5c-6.903 0-12.5 5.598-12.5 12.5 0 6.903 5.597 12.5 12.5 12.5h213.654l-58.66 58.662c-4.88 4.882-4.88 12.796 0 17.678 2.44 2.44 5.64 3.66 8.84 3.66s6.398-1.22 8.84-3.66l79.997-80c4.883-4.882 4.883-12.796 0-17.678z' />
                </svg>
              </div>
            </div>
            <div className='link_wrapper'>
              <a className='a-tag' href='#' onClick={onInstructionButton}>
                Instructions
              </a>
              <div className='icon'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 268.832 268.832'
                >
                  <path d='M265.17 125.577l-80-80c-4.88-4.88-12.796-4.88-17.677 0-4.882 4.882-4.882 12.796 0 17.678l58.66 58.66H12.5c-6.903 0-12.5 5.598-12.5 12.5 0 6.903 5.597 12.5 12.5 12.5h213.654l-58.66 58.662c-4.88 4.882-4.88 12.796 0 17.678 2.44 2.44 5.64 3.66 8.84 3.66s6.398-1.22 8.84-3.66l79.997-80c4.883-4.882 4.883-12.796 0-17.678z' />
                </svg>
              </div>
            </div>
          </div>
        </Bounce>
      </div>

      <div className='socialMedia' id='socialMedia'>
        <div>
          <FacebookFilled
            className='socialMedia-icons'
            id='socialMedia-FacebookFilled'
          />
        </div>
        <div>
          <InstagramFilled
            className='socialMedia-icons'
            id='socialMedia-InstagramFilled'
          />
        </div>
        <div>
          <TwitterSquareFilled
            className='socialMedia-icons'
            id='socialMedia-TwitterSquareFilled'
          />
        </div>
        <div>
          <img
            src={telegram}
            alt='telegram'
            className='socialMedia-icons'
            id='socialMedia-telegram'
          />
        </div>
      </div>

      <div className='BelowPart'>
        <AnimationOnScroll
          animateOnce={true}
          animateIn='animate__fadeInLeft'
          duration={1}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <About />
          </Suspense>
        </AnimationOnScroll>
        <div className='News-div'>
          <h1 className='latest-news'>Latest News</h1>
          <Suspense fallback={<div>Loading...</div>}>
            <News />
          </Suspense>
        </div>
        <div>
          <AnimationOnScroll
            animateIn='animate__fadeInRight'
            animateOut='animate__fadeOutRight'
            duration={1}
            animateOnce={true}
          >
            <div className='corousel-div'>
              <Carousel
                onChange={onChange}
                onClickItem={onClickItem}
                onClickThumb={onClickItem}
                centerMode={true}
                infiniteLoop={true}
                autoPlay={cAutoPlay}
                width={deviceCheck}
                useKeyboardArrows={true}
                centerSlidePercentage={95}
                transitionTime={1000}
                renderIndicator={false}
              >
                <div className='imageCorousel'>
                  <img src={images['bck (1).png'].default} alt='Here' />
                  <p className='legend'>{data[0].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (2).png'].default} alt='Here' />
                  <p className='legend'>{data[1].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (3).png'].default} alt='Here' />
                  <p className='legend'>{data[2].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (4).png'].default} alt='Here' />
                  <p className='legend'>{data[3].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (5).png'].default} alt='Here' />
                  <p className='legend'>{data[4].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (6).png'].default} alt='Here' />
                  <p className='legend'>{data[5].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (7).png'].default} alt='Here' />
                  <p className='legend'>{data[6].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (8).png'].default} alt='Here' />
                  <p className='legend'>{data[7].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (9).png'].default} alt='Here' />
                  <p className='legend'>{data[8].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (10).png'].default} alt='Here' />
                  <p className='legend'>{data[9].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (11).png'].default} alt='Here' />
                  <p className='legend'>{data[10].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (12).png'].default} alt='Here' />
                  <p className='legend'>{data[11].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (13).png'].default} alt='Here' />
                  <p className='legend'>{data[12].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (14).png'].default} alt='Here' />
                  <p className='legend'>{data[13].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (15).png'].default} alt='Here' />
                  <p className='legend'>{data[14].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (16).png'].default} alt='Here' />
                  <p className='legend'>{data[15].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (17).png'].default} alt='Here' />
                  <p className='legend'>{data[16].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (18).png'].default} alt='Here' />
                  <p className='legend'>{data[17].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (19).png'].default} alt='Here' />
                  <p className='legend'>{data[18].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (20).png'].default} alt='Here' />
                  <p className='legend'>{data[19].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (21).png'].default} alt='Here' />
                  <p className='legend'>{data[20].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (22).png'].default} alt='Here' />
                  <p className='legend'>{data[21].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (23).png'].default} alt='Here' />
                  <p className='legend'>{data[22].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (24).png'].default} alt='Here' />
                  <p className='legend'>{data[23].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (25).png'].default} alt='Here' />
                  <p className='legend'>{data[24].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (26).png'].default} alt='Here' />
                  <p className='legend'>{data[25].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (27).png'].default} alt='Here' />
                  <p className='legend'>{data[26].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (28).png'].default} alt='Here' />
                  <p className='legend'>{data[27].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (29).png'].default} alt='Here' />
                  <p className='legend'>{data[28].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (30).png'].default} alt='Here' />
                  <p className='legend'>{data[29].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (31).png'].default} alt='Here' />
                  <p className='legend'>{data[30].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (32).png'].default} alt='Here' />
                  <p className='legend'>{data[31].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (33).png'].default} alt='Here' />
                  <p className='legend'>{data[32].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (34).png'].default} alt='Here' />
                  <p className='legend'>{data[33].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (35).png'].default} alt='Here' />
                  <p className='legend'>{data[34].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (36).png'].default} alt='Here' />
                  <p className='legend'>{data[35].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (37).png'].default} alt='Here' />
                  <p className='legend'>{data[36].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (38).png'].default} alt='Here' />
                  <p className='legend'>{data[37].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (39).png'].default} alt='Here' />
                  <p className='legend'>{data[38].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (40).png'].default} alt='Here' />
                  <p className='legend'>{data[39].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (41).png'].default} alt='Here' />
                  <p className='legend'>{data[40].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (42).png'].default} alt='Here' />
                  <p className='legend'>{data[41].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (43).png'].default} alt='Here' />
                  <p className='legend'>{data[42].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (44).png'].default} alt='Here' />
                  <p className='legend'>{data[43].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (45).png'].default} alt='Here' />
                  <p className='legend'>{data[44].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (46).png'].default} alt='Here' />
                  <p className='legend'>{data[45].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (47).png'].default} alt='Here' />
                  <p className='legend'>{data[46].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (48).png'].default} alt='Here' />
                  <p className='legend'>{data[47].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (49).png'].default} alt='Here' />
                  <p className='legend'>{data[48].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (50).png'].default} alt='Here' />
                  <p className='legend'>{data[49].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (51).png'].default} alt='Here' />
                  <p className='legend'>{data[50].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (52).png'].default} alt='Here' />
                  <p className='legend'>{data[51].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (53).png'].default} alt='Here' />
                  <p className='legend'>{data[52].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (54).png'].default} alt='Here' />
                  <p className='legend'>{data[53].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (55).png'].default} alt='Here' />
                  <p className='legend'>{data[54].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (56).png'].default} alt='Here' />
                  <p className='legend'>{data[55].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (57).png'].default} alt='Here' />
                  <p className='legend'>{data[56].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (58).png'].default} alt='Here' />
                  <p className='legend'>{data[57].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (59).png'].default} alt='Here' />
                  <p className='legend'>{data[58].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (60).png'].default} alt='Here' />
                  <p className='legend'>{data[59].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (61).png'].default} alt='Here' />
                  <p className='legend'>{data[60].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (62).png'].default} alt='Here' />
                  <p className='legend'>{data[61].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (63).png'].default} alt='Here' />
                  <p className='legend'>{data[62].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (64).png'].default} alt='Here' />
                  <p className='legend'>{data[63].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (65).png'].default} alt='Here' />
                  <p className='legend'>{data[64].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (66).png'].default} alt='Here' />
                  <p className='legend'>{data[65].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (67).png'].default} alt='Here' />
                  <p className='legend'>{data[66].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (68).png'].default} alt='Here' />
                  <p className='legend'>{data[67].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (69).png'].default} alt='Here' />
                  <p className='legend'>{data[68].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (70).png'].default} alt='Here' />
                  <p className='legend'>{data[69].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (71).png'].default} alt='Here' />
                  <p className='legend'>{data[70].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (72).png'].default} alt='Here' />
                  <p className='legend'>{data[71].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (73).png'].default} alt='Here' />
                  <p className='legend'>{data[72].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (74).png'].default} alt='Here' />
                  <p className='legend'>{data[73].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (75).png'].default} alt='Here' />
                  <p className='legend'>{data[74].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (76).png'].default} alt='Here' />
                  <p className='legend'>{data[75].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (77).png'].default} alt='Here' />
                  <p className='legend'>{data[76].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (78).png'].default} alt='Here' />
                  <p className='legend'>{data[77].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (79).png'].default} alt='Here' />
                  <p className='legend'>{data[78].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (80).png'].default} alt='Here' />
                  <p className='legend'>{data[79].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (81).png'].default} alt='Here' />
                  <p className='legend'>{data[80].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (82).png'].default} alt='Here' />
                  <p className='legend'>{data[81].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (83).png'].default} alt='Here' />
                  <p className='legend'>{data[82].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (84).png'].default} alt='Here' />
                  <p className='legend'>{data[83].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (85).png'].default} alt='Here' />
                  <p className='legend'>{data[84].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (86).png'].default} alt='Here' />
                  <p className='legend'>{data[85].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (87).png'].default} alt='Here' />
                  <p className='legend'>{data[86].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (88).png'].default} alt='Here' />
                  <p className='legend'>{data[87].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (89).png'].default} alt='Here' />
                  <p className='legend'>{data[88].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (90).png'].default} alt='Here' />
                  <p className='legend'>{data[89].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (91).png'].default} alt='Here' />
                  <p className='legend'>{data[90].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (92).png'].default} alt='Here' />
                  <p className='legend'>{data[91].name}</p>
                </div>
                <div className='imageCorousel'>
                  <img src={images['bck (93).png'].default} alt='Here' />
                  <p className='legend'>{data[92].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (94).png'].default} alt='Here' />
                  <p className='legend'>{data[93].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (95).png'].default} alt='Here' />
                  <p className='legend'>{data[94].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (96).png'].default} alt='Here' />
                  <p className='legend'>{data[95].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (97).png'].default} alt='Here' />
                  <p className='legend'>{data[96].name}</p>
                </div>

                <div className='imageCorousel'>
                  <img src={images['bck (98).png'].default} alt='Here' />
                  <p className='legend'>{data[97].name}</p>
                </div>
              </Carousel>
            </div>
          </AnimationOnScroll>
        </div>
      </div>
    </>
  )
}

export default LandingPage
