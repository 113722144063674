import { useEffect } from 'react'
import './App.scss'
import { Menu } from './components/Menu'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Home } from './pages/Home'
import { Page2 } from './pages/Page2'
import ProtectedRoute from './ProtectedRoute'
import LandingPage from './pages/LandingPage'
import Signin from './pages/Signin'

function App () {
  function importAll (r) {
    let images = {}
    r.keys().map((item, index) => {
      images[item.replace('./', '')] = r(item)
    })
    return images
  }

  const images = importAll(
    require.context('./images/Background', false, /\.(png|jpe?g|svg)$/)
  )

  let myIndex = 0
  useEffect(() => {
    carousel()
  })
  function carousel () {
    let i
    let x = document.getElementsByClassName('mySlides')
    for (i = 0; i < x.length; i++) {
      x[i].style.animation = 'mynewmove 8s infinite'
      x[i].style.display = 'none'
    }
    myIndex++
    if (myIndex > x.length) {
      myIndex = 1
    }
    x[myIndex - 1].style.animation = 'mynewmove2 8s infinite'
    x[myIndex - 1].style.display = 'block'

    setTimeout(carousel, 7500)
  }
  return (
    <div className='App'>
      <BrowserRouter>
        <Menu />
        <div className='slideshow'>
          <img className='mySlides' src={images['2.jpeg'].default} />
          <img className='mySlides' src={images['3.jpeg'].default} />
          <img className='mySlides' src={images['1.png'].default} />
          <img className='mySlides' src={images['2.png'].default} />
          <img className='mySlides' src={images['3.png'].default} />
          <img className='mySlides' src={images['4.png'].default} />
          <img className='mySlides' src={images['5.png'].default} />
          <img className='mySlides' src={images['6.png'].default} />
          <img className='mySlides' src={images['7.png'].default} />
          <img className='mySlides' src={images['8.png'].default} />
          <img className='mySlides' src={images['9.png'].default} />
          <img className='mySlides' src={images['10.png'].default} />
          <img className='mySlides' src={images['11.png'].default} />
          <img className='mySlides' src={images['12.png'].default} />
        </div>
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/page2' component={Page2} />
          <Route exact path='/home' component={Home} />
          <Route exact path='/signin' component={Signin} />
          <Route exact path='/crewsandclans' component={Home} />
          <Redirect to='/' />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App
