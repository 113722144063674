import React, { useState, useEffect } from 'react'
import './Menu.css'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import HomeIcon from '@mui/icons-material/Home'
import useMediaQuery from '@mui/material/useMediaQuery'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import DialogTitle from '@mui/material/DialogTitle'

import { useSelector } from 'react-redux'
import { ethers } from 'ethers'
import { useMoralis, useChain } from 'react-moralis'
import { Link, Redirect } from 'react-router-dom'
import LogoIcon from '../images/Logos/Logo3.png'
import EnterIcon from '../images/enter.svg'
import ExitIcon from '../images/exit.png'
import { useHistory } from 'react-router-dom'
import { User, UserService } from '../UserService'
import { useDispatch } from 'react-redux'
import { setPlayerLogout } from '../GlobalState/UserReducer'

export const Menu = props => {
  const [dOpen, setDOpen] = React.useState(false)
  const [wOpen, setWOpen] = React.useState(false)
  const [path, setPath] = React.useState(false)
  const theme = createTheme({
    palette: {
      mode: 'dark'
    }
  })
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const handleClickDOpen = () => {
    setDOpen(true)
  }

  const handleDClose = () => {
    setDOpen(false)
  }

  const handleClickWOpen = () => {
    setWOpen(true)
  }

  const handleWClose = () => {
    setWOpen(false)
  }

  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      let navId = document.getElementById('menu')
      scrollY === 0
        ? (navId.style.backgroundColor = 'transparent')
        : (navId.style.backgroundColor = '#000000d0')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    const onLoad = () => {
      if (window.location.pathname !== '/') {
        setPath(true)
      } else {
        setPath(false)
      }
      console.log(path)
    }
    let screenSize = window.screen.width
    if (screenSize <= 768) {
      setCrewsClanFunc(true)
    } else setCrewsClanFunc(false)

    window.addEventListener('load', onLoad)
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const dispatch = useDispatch()
  const locationHistory = useHistory()
  const UserState = useSelector(store => store.user)

  const [data, setdata] = useState({
    address: 'Address: NC',
    Balance: 'Balance: NC',
    status: 'Not Conected'
  })

  const { isAuthenticated, authenticate, logout, account } = useMoralis()
  const { chain } = useChain()
  const walletConnectAuth = async () => {
    try {
      await authenticate({
        provider: 'walletconnect',
        signingMessage: 'Auth Required by HODL APP'
      })
    } catch (error) {
      console.log(error)
    }
  }

  const walletConnectLogout = async () => {
    try {
      await logout()
    } catch (error) {
      console.log(error)
    }
  }

  const btnhandler = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(res => accountChangeHandler(res[0]))
    } else {
      alert('install metamask extension!!')
    }
  }

  const getbalance = address => {
    window.ethereum
      .request({
        method: 'eth_getBalance',
        params: [address, 'latest']
      })
      .then(balance => {
        setdata({
          Balance: ethers.utils.formatEther(balance),
          status: 'connected',
          address: address
        })
      })
  }

  const accountChangeHandler = accountProp => {
    setdata({
      address: accountProp
    })
    getbalance(accountProp)
  }

  const handleLogin = () => {
    UserService.login(() => {
      if (UserService.isLogged()) {
        locationHistory.push('/')
      } else {
        dispatch(setPlayerLogout())
      }
    })
  }

  const onHandleLogout = () => {
    UserService.logout()
  }

  const changeToClash = () => {
    console.log(path)
    setPath(false)
  }

  const changeToHome = () => {
    console.log(path)
    setPath(true)
  }

  const [crewsClanFunc, setCrewsClanFunc] = useState(false)

  // const crewsClanFunc = () => {
  //   let screenSize = window.screen.width
  //   console.log(screenSize)
  //   if (screenSize <= 768) {
  //     return false
  //   } else return false
  // }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog
          fullScreen={fullScreen}
          open={dOpen}
          onClose={handleDClose}
          aria-labelledby='responsive-dialog-title'
        >
          <DialogTitle id='responsive-dialog-title'>
            {'Metamask Wallet Information'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Address: {data.address}</DialogContentText>
            <DialogContentText>Balance: {data.Balance}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                // logoutMetamask()
                handleDClose()
              }}
              autoFocus
            >
              Logout MetaMask
            </Button>
            <Button onClick={handleDClose} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullScreen={fullScreen}
          open={wOpen}
          onClose={handleWClose}
          aria-labelledby='responsive-dialog-title'
        >
          <DialogTitle id='responsive-dialog-title'>
            {'Wallet Connect Information'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Address: {account}</DialogContentText>
            <DialogContentText>Chain: {chain?.name}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                walletConnectLogout()
                handleWClose()
              }}
              autoFocus
            >
              Logout WalletConnect
            </Button>
            <Button onClick={handleWClose} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <nav
        id='menu'
        className='d-flex justify-content-between align-items-center px-5'
      >
        {UserState.isLogged ? <Redirect to='/' /> : <></>}
        <div className='d-flex align-items-center logoImage'>
          <Link to='/'>
            <img src={LogoIcon} alt='LogoIcon' width='95' />
          </Link>
          <div className='ms-4 text-white extra-info'>
            {UserState.isLogged
              ? `${UserState.name} - Wallet: ${UserState.balance}`
              : ''}
          </div>
        </div>
        <div className='d-flex'>
          {path ? (
            <Link to='/' onClick={changeToClash}>
              <div className='button-valued-noBorder'>
                <HomeIcon color='#bb2d3b' />
              </div>
            </Link>
          ) : (
            <Link to='/crewsandclans' onClick={changeToHome}>
              <div className='button-valued' id='button-5'>
                <div id='translate'></div>
                {crewsClanFunc ? (
                  <a href='#' className='a-href'>
                    Crew
                  </a>
                ) : (
                  <a href='#' className='a-href'>
                    Crews & Clans
                  </a>
                )}
              </div>
            </Link>
          )}
          <div className='text-white extra-info-2'>
            <div className='button-valued' id='button-3'>
              <div id='circle'></div>
              <a
                href='#'
                className='a-href'
                onClick={
                  data.status === 'Not Conected' ? btnhandler : handleClickDOpen
                }
              >
                MetaMask
              </a>
            </div>
          </div>

          <div className='text-white extra-info-2'>
            <div className='button-valued' id='button-2'>
              <div id='slide'></div>
              <a
                href='#'
                className='a-href'
                onClick={
                  account === null ? walletConnectAuth : handleClickWOpen
                }
              >
                Wallet Connect
              </a>
            </div>
          </div>

          {!UserState.isLogged && (
            <Link to='/' onClick={handleLogin}>
              <div className='button-valued-log' id='button-7'>
                <div id='dub-arrow'>
                  <img src={EnterIcon} alt='Enter Icon' />
                </div>
                <a href='#' className='a-href-log'>
                  Login
                </a>
              </div>
            </Link>
          )}
          {UserState.isLogged && (
            <Link to='/' className='btn-item p-1' onClick={onHandleLogout}>
              <div className='button-valued-log' id='button-7'>
                <div id='dub-arrow'>
                  <img src={ExitIcon} alt='exit Icon' />
                </div>
                <a href='#' className='a-href'>
                  Logout
                </a>
              </div>
            </Link>
          )}
        </div>
      </nav>
    </>
  )
}
