import React, { useState } from 'react'
import InputComp from '../components/Input'
import './Signin.css'

const Signin = () => {
  const [passwordCheck, setPasswordCheck] = useState('')
  const handleSubmit = async event => {
    event.preventDefault()
    console.log({
      email: event.target[0].value,
      password: event.target[1].value
    })

    if (
      event.target[0].value === 'HODLTEST' &&
      event.target[1].value === '1mah0dlwarr10r'
    ) {
      window.location.href = 'http://gkgame.s3-website-us-east-1.amazonaws.com/'
    } else {
      setPasswordCheck('Wrong Credentials! Try Again')
    }
  }

  return (
    <div className='center-div'>
      <div className='signin-box'>
        <div className='signin-content'>
          <p className='statement-para'>Sign in for Beta Testing</p>
          <form onSubmit={handleSubmit}>
            <InputComp label='USERNAME' id='email' type='text' />
            <InputComp label='PASSWORD' id='password' type='password' />
            <div className='signed-row'>
              <p className='statement-para-wrong'>{passwordCheck}</p>
              <a className='forgot-link' href='/'>
                Forgot your password?
              </a>
            </div>
            <div>
              <button type='submit' className='continue-button'>
                SIGN IN (BETA)
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Signin
