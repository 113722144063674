import image1 from '../../images/FINAL/main (1).jpg'
import image2 from '../../images/FINAL/main (2).jpg'
import image3 from '../../images/FINAL/main (3).jpg'
import image4 from '../../images/FINAL/main (4).jpg'
import image5 from '../../images/FINAL/main (5).jpg'
import image6 from '../../images/FINAL/main (6).jpg'
import image7 from '../../images/FINAL/main (7).jpg'
import image8 from '../../images/FINAL/main (8).jpg'
import image9 from '../../images/FINAL/main (9).jpg'
import image10 from '../../images/FINAL/main (10).jpg'
import image11 from '../../images/FINAL/main (11).jpg'
import image12 from '../../images/FINAL/main (12).jpg'
import image13 from '../../images/FINAL/main (13).jpg'
import image14 from '../../images/FINAL/main (14).jpg'
import image15 from '../../images/FINAL/main (15).jpg'
import image16 from '../../images/FINAL/main (16).jpg'
import image17 from '../../images/FINAL/main (17).jpg'
import image18 from '../../images/FINAL/main (18).jpg'
import image19 from '../../images/FINAL/main (19).jpg'
import image20 from '../../images/FINAL/main (20).jpg'
import image21 from '../../images/FINAL/main (21).jpg'
import image22 from '../../images/FINAL/main (22).jpg'
import image23 from '../../images/FINAL/main (23).jpg'
import image24 from '../../images/FINAL/main (24).jpg'
import image25 from '../../images/FINAL/main (25).jpg'

const mainImages = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
  image25
]

export default mainImages
